import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/iconplugins.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import '../../assets/images/favicon.png'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import {
    parsePhoneNumberFromString,
    isValidPhoneNumber,
    formatPhoneNumberIntl,
} from "libphonenumber-js";
import { FaEye, FaEyeSlash, FaLinkedin } from "react-icons/fa";
import {
    CitySelect,
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { SendOtp } from "../../api/Auth"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import CommonImageTransport from '../../components/common/images/CommonImages';
import Header from '../../components/common/header/Header';
import moment from 'moment-timezone';
import Modal from "react-bootstrap/Modal";
import { PrivacyPolicy, TermsCondtion } from '../../api/Global';
import { PhoneValidLenght } from '../../components/common/phonevalidlength/PhoneValidLenght';
import { countries } from '../../components/common/countrylist/CountryList';
import ReactHtmlParser from "react-html-parser";

const Mentor = () => {
    const navigate = useNavigate();
    const [ActiveTabs, setAciveTabs] = useState("Mentor")
    const [isLoading, setIsLoading] = useState(false);
    const [defaultCountryCode, setDefaultCountryCode] = useState("US")
    const [defaultState, setDefaultState] = useState("")
    const [defaultCity, setDefaultCity] = useState("")
    const [value, setValue] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [country, setCountry] = useState({
        capital: "Washington", currency: "USD",
        currency_name: "United States dollar", currency_symbol: "$", emoji: "🇺🇸", id: 233, iso2: "US",
        iso3: "USA", latitude: "38.00000000", longitude: "-97.00000000", name: "United States",
        native: "United States", numeric_code: "840", phone_code: 1, region: "Americas",
        subregion: "Northern America", tld: ".us"
    });
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [Email, setEmail] = useState('');
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [DOB, setDOB] = useState("");
    const [selectNationlity, setSelectNationlity] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState()
    const [countryCode, setCountryCode] = useState()
    const [error, setError] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [countryid, setCountryid] = useState(country.id);
    const [stateid, setstateid] = useState(0);
    const [cityid, setcityid] = useState(0);

    const DateDataFirst = new Date();
    const timeZone = moment.tz.guess();
    const timeZoneAbbr = moment.tz(DateDataFirst, timeZone).format('z');
    console.log("timeZoneAbbr", timeZoneAbbr)

    // <------ Validation for the all parameters ---------->
    const [mobileError, setMobileError] = useState("");

    // <---------- Show the Popup T&C ------------->
    const [ShowTermsCondition, setShowTermsCondition] = useState(false);

    // <---------- Show the Popup T&C ------------->
    const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    // <----------- Check the T & C -------------->
    const [isTACChecked, setIsTACChecked] = useState(false);

    // <------------ P & P ----------------->
    const [PrivacyPolicyList, setPrivacyPolicyList] = useState({});
    const [TermsConditionList, setTermsConditionList] = useState({});

    // Get today's date
    const today = new Date();

    // Calculate the date 18 years ago
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() - 21);

    // Convert the max date to ISO format for the input
    const maxDateString = maxDate.toISOString().split("T")[0];

    useEffect(() => {
        if (value && validatePhoneNumber(value)) {
            const parsedPhoneNumber = parsePhoneNumberFromString(
                value,
                defaultCountryCode
            );
            if (parsedPhoneNumber) {
                setCountryCode(parsedPhoneNumber.defaultCountryCode);
                setPhoneNumber(`+${parsedPhoneNumber.countryCallingCode}-${parsedPhoneNumber.nationalNumber}`);

            }
        }
    }, [value]);


    const handleInputChange = (event, setter, setError) => {
        const value = event.target.value;
        setter(value);
        const regex = /^[a-zA-Z\s]+$/;
        if (!regex.test(value)) {
            setError('Name should contain only alphabetical letters');
        } else {
            setError('');
        }
    };


    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        validateEmail(value);
    };

    const validateEmail = (email) => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setIsValidEmail(regex.test(email));
    };

    const ValidatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}$/;
        setIsValidPassword(regex.test(password))
    }

    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setPassword(value);
        ValidatePassword(value);
    }

    const validatePhoneNumber = (phoneNumber) => {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedPhoneNumber) {
            setMobileError("Please enter a valid phone number.");
            return false;
        }
        const isValid = isValidPhoneNumber(phoneNumber);
        const countryCode = parsedPhoneNumber.country;

        if (isValid) {
            const phoneNumberLength = parsedPhoneNumber.nationalNumber.length;
            if (PhoneValidLenght[countryCode] && phoneNumberLength !== PhoneValidLenght[countryCode]) {
                setMobileError(`Please enter a valid ${countryCode} phone number with ${PhoneValidLenght[countryCode]} digits.`);
                return false;
            }

            setMobileError("");
            return true;
        } else {
            setMobileError(`Please enter a valid phone number.`);
            return false;
        }
    };

    const handleNationlityChange = (e) => {
        setSelectNationlity(e.target.value);
    };

    const validateForm = () => {
        if (
            firstName.trim() === '' ||
            lastName.trim() === '' ||
            Email.trim() === '' ||
            password.trim() === '' ||
            confirmPassword.trim() === '' ||
            !value
        ) {
            setError('Please enter all fields');
            return false;
        }
        setError('');
        return true;
    };

    // <---------- Checked the Terms & Condition's ------------>
    const handleCheckboxChange = () => {
        setIsTACChecked(!isTACChecked);
    };

    //    <-------------- Country Selected by Id ------------------------->
    const funCountryId = (e) => {
        setDefaultCountryCode(e.iso2)
        setCountryid(e.id);
    }

    //    <-------------- State Selected by Id ------------------------->
    const funStateId = (e) => {
        setDefaultState(e.iso2)
        setstateid(e)
    }

    //    <-------------- City Selected by Id ------------------------->
    const funCityId = (e) => {
        setDefaultCity(e.iso2)
        setcityid(e)
    }



    // <---------- 
    const SendOtpMentorSignUp = async () => {
        setIsLoading(true);
        try {
            // Validate the form first
            if (!validateForm()) {
                setIsLoading(false);
                return;
            }

            // Check if passwords match
            if (password !== confirmPassword) {
                toast.error("Password Do Not Match !!!");
                setIsLoading(false);
                return;
            }

            // Check country, state, and city
            if (!country || !state) {
                toast.error('Please select country, state');
                setIsLoading(false);
                return;
            }

            // Check password length
            if (password.length < 10) {
                toast.error("Password must be at least 10 characters long.");
                setIsLoading(false);
                return;
            }

            if (confirmPassword.length < 10) {
                toast.error("Confirm Password must be at least 10 characters long.");
                setIsLoading(false);
                return;
            }
            // if (!DOB) {
            //     toast.error("Please select your date of brith");
            //     setIsLoading(false)
            //     return;
            // }
            // if (!selectNationlity) {
            //     toast.error("Please select your Nationlity");
            //     setIsLoading(false)
            //     return;
            // }

            if (firstNameError || lastNameError || !firstName || !lastName) {
                toast.error("Name should contain only alphabetical letters");
                setIsLoading(false);
                return;
            }

            if (!isValidEmail) {
                toast.error("Please enter a valid email address.");
                setIsLoading(false);
                return;
            }

            if (!validatePhoneNumber(value)) {
                toast.error("Please enter a valid phone number.");
                setIsLoading(false);
                return;
            }

            if (!isTACChecked) {
                toast.error("Please accept the Terms & Conditions and Privacy Policy.");
                setIsLoading(false);
                return;
            }

            // Prepare user data for OTP sending
            const userData = {
                type: "signup",
                email: Email
            };

            const MentorData = {
                role_id: 3,
                fname: firstName,
                lname: lastName,
                email: Email,
                password,
                countryCode: countryCode,
                mobile: phoneNumber,
                dob: DOB,
                citizen:selectNationlity,
                city: city,
                state: state,
                country: country.name,
                tcCheck: 1,
                time_zone: timeZoneAbbr
            };

            // Send OTP
            const response = await SendOtp(userData);
            console.log("SendOtpMentorSignUp", response.data.msg);

            if (response.data.statusCode === "200") {
                toast.success("Otp sent to your email, please check");
                localStorage.setItem("Mentor_Register_Email", response.data.data.email);
                localStorage.setItem("Mentor_Register_Data", JSON.stringify(MentorData));
                setTimeout(() => {
                    navigate("/MentorSignUpOtpVerify");
                    setIsLoading(false);
                }, 2000);
            } else if (response.data.statusCode == "400") {
                setIsLoading(false);
                setTimeout(() => {
                    setIsLoading(false);
                    toast.warn(response?.data?.msg);
                    window.location.reload();
                }, 1000);
            }

        } catch (error) {
            setIsLoading(false)
            console.log("Error:", error);
        }
    };


    const getRecruiter = localStorage.getItem("HandleRecruiter")
    const getMentorship = localStorage.getItem("HandleMentorship")

    useEffect(() => {
        document.title = '::Signup::';
    }, []);

    const RemoveLocalStorage = () => {
        localStorage.removeItem("HandleMentorship");
        localStorage.removeItem("HandleRecruiter");
    }

    const StudentSignUpPage = () => {
        navigate("/Student_SignUp")
        localStorage.setItem("ActiveTabs", "student")
    }

    const RecuriterSignUpPage = () => {
        navigate("/Recruiter_SignUp")
        localStorage.setItem("ActiveTabs", "Recruiter")
    }

    const MentorSignUpPage = () => {
        navigate("/Mentor_SignUp")
        localStorage.setItem("ActiveTabs", "Mentor")
    }

    // <---------- Get Privacy & ploicy ------------->
    const GetPrivacyPolicy = async () => {
        try {
            const response = await PrivacyPolicy();
            setPrivacyPolicyList(response?.data?.data)
        } catch (error) {
            console.log("error", error)
        }
    }

    // <---------- Get Privacy & ploicy ------------->
    const GetTermsCondtion = async () => {
        try {
            const response = await TermsCondtion();
            setTermsConditionList(response?.data?.data)
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        GetPrivacyPolicy();
        GetTermsCondtion();
    }, [])
    return (
        <div>


            <div className="login-signup-bg">
                <Header />
                {
                    isLoading ? (
                        <>
                            <div className="chat-window text-center">
                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    margin: "auto",
                                }} className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 p-0 d-none d-md-none d-lg-block">
                                            <div className="user_login">
                                                <img src={CommonImageTransport.loginbg} className="imgk-fluid w-100" alt="login-banner" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-flex p-0">
                                            <div className="user-all-form userlogin position-relative">
                                                <div className="login_bottom">
                                                    <img src={CommonImageTransport.loginBottom} alt="" />
                                                </div>
                                                <div className="contact-form m-0 py-0">

                                                    <h3 className="user-title"> Sign up for an account</h3>

                                                    <ul className="nav tabs_signup justify-content-start gap-2">

                                                        <li className="nav-item">
                                                            <a className={`${ActiveTabs == "student" ? "nav-link active" : "nav-link"}`}
                                                                aria-current="page" onClick={() => {
                                                                    RemoveLocalStorage();
                                                                    StudentSignUpPage()
                                                                }}>Student</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={`${ActiveTabs == "Recruiter" ? "nav-link active" : "nav-link"}`} onClick={() => {
                                                                RecuriterSignUpPage()
                                                            }}
                                                            >Recruiter</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={`${ActiveTabs == "Mentor" ? "nav-link active" : "nav-link"}`} onClick={() => {
                                                                MentorSignUpPage()
                                                            }}
                                                            >Mentor</a>
                                                        </li>
                                                    </ul>
                                                    <ToastContainer style={{ marginTop: "120px" }} />


                                                    {/* <Link to='https://www.linkedin.com/'
                                                     className='linkedbtn d-flex justify-content-center align-items-center gap-2'><FaLinkedin className='LinkedInIcon' />
                                                     LinkedIn</Link> */}

                                                    {/* <h6 className='shift text-center'>Or Continue with</h6> */}

                                                    <form id="contactForms" className="mt-2">
                                                        <div className="row">
                                                            {/* <div className="col-lg-12 mb-4">
                                                                <h6>Location</h6>
                                                            </div> */}
                                                            <div className='col-md-4'>
                                                                <div className="form-floating form-group">
                                                                    <h6>Country</h6>
                                                                    <CountrySelect
                                                                        defaultValue={country}
                                                                        value={country}
                                                                        onChange={(selectedCountry) => {
                                                                            setCountry(selectedCountry);
                                                                            funCountryId(selectedCountry)
                                                                        }}
                                                                        placeHolder="Select Country"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='col-md-4'>
                                                                <h6>State</h6>
                                                                <StateSelect
                                                                    country={country}
                                                                    value={state}
                                                                    countryid={countryid}
                                                                    onChange={(selectedState) => {
                                                                        funStateId(selectedState.id)
                                                                        setState(selectedState.name);
                                                                    }}
                                                                    placeHolder="Select State"
                                                                />
                                                            </div>

                                                            <div className='col-md-4'>
                                                                <h6>City</h6>
                                                                <CitySelect
                                                                    country={country}
                                                                    state={state}
                                                                    value={city}
                                                                    countryid={countryid}
                                                                    stateid={stateid}
                                                                    cityid={cityid}
                                                                    onChange={(selectedCity) => {
                                                                        funCityId(selectedCity.id)
                                                                        setCity(selectedCity.name);
                                                                    }}
                                                                    placeHolder="Select City"
                                                                />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control" id="floatingInput"
                                                                        placeholder="" name="firstName" required
                                                                        value={firstName}
                                                                        onChange={(event) =>
                                                                            handleInputChange(event, setFirstName, setFirstNameError)
                                                                        } />
                                                                    <label htmlFor="floatingInput">First Name</label>
                                                                    {firstNameError && <p style={{ color: 'red', fontSize: '14px' }}>{firstNameError}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control" id="floatingInput"
                                                                        placeholder="" name="lastName" required
                                                                        value={lastName}
                                                                        onChange={(event) =>
                                                                            handleInputChange(event, setLastName, setLastNameError)
                                                                        } />
                                                                    <label htmlFor="floatingInput">Last Name</label>
                                                                    {lastNameError && <p style={{ color: 'red', fontSize: '14px' }}>{lastNameError}</p>}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="form-floating form-group">
                                                                    <input type="email" className="form-control" id="floatingInput" placeholder="" onChange={handleChange} required
                                                                    />

                                                                    <label htmlFor="floatingInput">Email</label>
                                                                    {!isValidEmail && <p style={{ color: 'red' }}>Incorrect Email</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6 position-relative">
                                                                <span className="form-floating form-group">
                                                                    <input
                                                                        type={showPassword ? "text" : "password"}
                                                                        className="form-control"
                                                                        id="floatingPassword"
                                                                        placeholder="xxxxxxxx"
                                                                        required
                                                                        value={password}
                                                                        onChange={(e) => handlePasswordChange(e)}
                                                                    />
                                                                    <label htmlFor="floatingPassword">Password</label>

                                                                </span>
                                                                <span onClick={() => setShowPassword(!showPassword)} className="password-toggle eye"
                                                                >
                                                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                                </span>
                                                            </div>
                                                            <div className="col-12 col-lg-6 position-relative">
                                                                <div className="form-floating form-group">
                                                                    <input type={showConfirmPassword ? "text" : "password"} className="form-control" id="floatingPassword"
                                                                        placeholder="xxxxxxxx"
                                                                        value={confirmPassword}
                                                                        required
                                                                        onChange={(e) => setConfirmPassword(e.target.value)} />
                                                                    <label htmlFor="floatingPassword">Confirm password</label>
                                                                </div>
                                                                <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="password-toggle eye"
                                                                >
                                                                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                                                </span>
                                                            </div>
                                                            {!isValidPassword && <p style={{ color: 'red' }}>Password must be at least 11 characters long, contain at least
                                                                one lowercase letter, one uppercase letter, one digit, and one special character (@$!%*?&).</p>}
                                                            <div className="col-lg-12 position-relative">
                                                                <div className="form-floating form-group">
                                                                    <PhoneInput
                                                                        className="phoneinput form-control"
                                                                        international
                                                                        countryCallingCodeEditable={false}
                                                                        defaultCountry={defaultCountryCode}
                                                                        placeholder="Enter phone number"
                                                                        value={value}
                                                                        onChange={setValue}
                                                                    // onChange={(newValue) => {
                                                                    //     const validPhoneNumber = newValue || '';
                                                                    //     setValue(validPhoneNumber);
                                                                    //     validatePhoneNumber(validPhoneNumber);
                                                                    // }} 
                                                                    />
                                                                    {mobileError && (
                                                                        <p style={{ color: "red", fontSize: "14px" }}>
                                                                            {mobileError}
                                                                        </p>
                                                                    )}

                                                                </div>
                                                            </div>

                                                            {/* <div className="col-lg-6 col-12">
                                                                <div className="form-floating form-group">

                                                                    <input
                                                                        type="date" className="form-control"
                                                                        value={
                                                                            DOB
                                                                                ? new Date(DOB)
                                                                                    .toISOString()
                                                                                    .split("T")[0]
                                                                                : ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            setDOB(e.target.value)
                                                                        }
                                                                        name="DOB"
                                                                        placeholder="dob"
                                                                        max={maxDateString}
                                                                        required
                                                                    />
                                                                    <label className="floatingInput">
                                                                        DOB
                                                                    </label>
                                                                    {DOB && new Date(DOB) > maxDate && (
                                                                        <p style={{ color: "red" }}>
                                                                            You must be at least 18 years old.
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <div className="form-group">
                                                                    <select id="country-select" value={selectNationlity} onChange={handleNationlityChange} className="form-control">
                                                                        <option value="">--Select a Nationality--</option>
                                                                        {countries?.map((country, index) => (
                                                                            <option key={index} value={country}>
                                                                                {country}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-lg-12 form-condition">
                                                                <div className="agree-label">
                                                                    <input type="checkbox" id="chb1" onChange={handleCheckboxChange}
                                                                        checked={isTACChecked} />
                                                                    <label htmlFor="chb1" className="small" >
                                                                        All your information is collected, stored and processed as per our data
                                                                        processing guidelines. By signing up on FreshGrad, you agree to our <Link to="#" onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setShowPrivacyPolicy(true);
                                                                        }}>Privacy
                                                                            Policy</Link> <Link to="#">and</Link> <Link to="#" onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setShowTermsCondition(true);
                                                                            }}>Terms of Use</Link>
                                                                    </label>
                                                                    {/* {!isTACChecked && <p style={{ color: 'red' }}>Please accept the terms and conditions before signing up.</p>} */}
                                                                </div>
                                                            </div>
                                                            {error && <p style={{ color: 'red', fontSize: '14px' }}>{error}</p>}
                                                            <div className="col-lg-12 col-md-12">
                                                                <button type='button' className="default-btn w-100 rounded" onClick={SendOtpMentorSignUp}>
                                                                    Sign Up
                                                                </button>
                                                                <h6 className="mt-4 text-center fw-normal text-muted">Already have an account? <Link
                                                                    className="fw-bold" to="/Signin">Login</Link></h6>
                                                            </div>
                                                        </div>
                                                    </form>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    )
                }



            </div>

            {/* <--------- T & C Modal section's ------------> */}
            <Modal
                size="lg"
                show={ShowTermsCondition}
                onHide={() => setShowTermsCondition(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row pt-1">
                            <div className="col-lg-12">
                                <div className="single-content">
                                    <h3>{TermsConditionList?.title}</h3>
                                    <ul>
                                        <li>
                                        <p>{ReactHtmlParser(TermsConditionList?.desciption)}</p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* <--------- Privacy & Policy section's ------------> */}
            <Modal
                size="lg"
                show={ShowPrivacyPolicy}
                onHide={() => setShowPrivacyPolicy(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Privacy & Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row pt-1">
                            <div className="col-lg-12">
                                <div className="single-content">
                                    <h3>{PrivacyPolicyList?.title}</h3>
                                    <ul>
                                        <li>
                                        <p>{ReactHtmlParser(PrivacyPolicyList?.desciption)}</p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Mentor
