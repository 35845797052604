import React from "react";
import CommonImageTransport from "../../../../common/images/CommonImages";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { IMG_BASE_URL } from "../../../../../base_url/Base_URL";

const TrainerBlock = () => {
  const navigate = useNavigate();
  const AddAllMentors = async (e) => {
    e.preventDefault();
    const role_id = localStorage.getItem("role_id");
    if (role_id === "2") {
      navigate("/Student/Student_Mentor_List");
    } else if (role_id === "3" || role_id === "4") {
      toast.error("You Can't access !!!");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
    else {
      toast.error("User Not Sign in here !!!");
      setTimeout(() => {
        navigate("/Signin");
      }, 2000);
    }
  }
  return (
    <div className="featured-area bg-light py-5">

      <div class="container">
        <ToastContainer style={{ marginTop: "120px" }} />
        <div class="row align-items-center mb-45">
          <div class="col-lg-8 col-md-9">
            <div class="section-title mt-rs-20">
              <h2>Unlock Your Career Potential</h2>
              <p>Graduation is the first step toward building a successful career. Identify your goals,
                embrace networking and mentorship, and commit to continuous learning to stay competitive.
                Highlight your unique strengths and create a compelling personal brand. Stay resilient,
                adapt to challenges, and seize opportunities to achieve your professional aspirations.</p>
            </div>
          </div>
          <div className="col-lg-4  col-md-3 text-end">
            <img src={CommonImageTransport.users} alt="" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-7 col-lg-9">
            <div class="row career-se">
              <div class="col-lg-8 col-12 mb-3">
                <div class="banner-item position-relative">
                  <span>Free</span>
                  <h3>
                  FREE Job-Oriented 
                    <br />
                    Hands-On Training Programs
                  </h3>
                  <Link to="/course_list" class="e-btn">
                    View Courses
                  </Link>
                  <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                    <img src={CommonImageTransport?.careerbanner} 

                    alt="img" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <div class="banner-item trainer-ban position-relative">
                  <span>Trainer</span>
                  <h3>
                    Trainer skills
                    <br /> daily
                  </h3>
                  <Link to="#" onClick={AddAllMentors} class="e-btn">
                    View
                  </Link>
                  <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                    <img
                      src={`${IMG_BASE_URL}/career-banner2.ccebec50786e4c8eab21.png`}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <div class="banner-item careerbg trainer-ban position-relative">
                  <div class="banner-content"></div>
                  <span>Careers</span>
                  <h3>
                    Jobs <br />
                    Explore
                  </h3>
                  <Link to="/Jobs" class="e-btn">
                    View
                  </Link>
                  <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                    <img
                      src={`${IMG_BASE_URL}/career-banner3.653873e31b3fd67ed9b1.png`}
                      // src="https://freshgradpublic.s3.us-east-1.amazonaws.com/Training+Program_files/"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-12 mb-3">
                <div class="banner-item menterbg position-relative">
                  <span>Top Mentors</span>
                  <h3>
                    Mentorship <br />
                    Guidance
                  </h3>
                  <Link to="/Mentorship" class="e-btn">
                    View
                  </Link>
                  <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                    <img
                      src={`${IMG_BASE_URL}/career-banner4.f0eb3bfb110dbcd8671b.png`}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-lg-3">
            <div class="banner-item companybg trainer-ban position-relative">
              <div class="content-containers">
                <div class="company">
                  <span>Company</span>
                </div>
                <div class="title">
                  <h3>
                    Compete Battle <br />
                    For Excellence
                  </h3>
                </div>
                {/* <div class="button-container">
                    <a href="#" class="e-btn">
                      View
                    </a>
                  </div> */}
              </div>

              <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                <img  src={`${IMG_BASE_URL}/career-banner5.b04d1937dc0372836ae1.png`} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TrainerBlock;
