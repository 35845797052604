import React, { useEffect, useState } from "react";
import "../../../../assets/css/iconplugins.css";
import "../../../../assets/css/style.css";
import "../../../../assets/css/responsive.css";
import "../../../../assets/images/favicon.png";
import { Link } from "react-router-dom";
import Subscribe from "../../../common/subscribe/Subscribe";
import Footer from "../../../common/footer/Footer";
import BackToTop from "../../../common/back_to_top/Back_To_Top";
import Header from "../../../common/header/Header";
import Loader from "../../../../loader/Loader";
import CommonImageTransport from "../../../common/images/CommonImages";
import TopMentors from "../home/home_components/Top_Mentors";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GetCourseList } from "../../../../api/Global";
import { IMG_BASE_URL } from "../../../../base_url/Base_URL";

const TrainingPrograms = () => {
  const navigate = useNavigate();
  const [CourseList, setCourseList] = useState([]);
  const [Course_id, setCourse_id] = useState("");
  // <--------- This is UseState for Loader Pages-------->
  // const [isLoading, setIsLoading] = useState(true);

  // <--------- This UseEffect for Loader Pages-------->
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  //   return () => clearTimeout(timeout);
  // }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.title = "Training Program";
      document
        .querySelector(".banner-img")
        .classList.add("animate-from-bottom");
      document
        .querySelector(".banner-content")
        .classList.add("animate-from-top");
      // setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const HandleCourseList = async () => {
    const CourseData = {
      Course_id,
    };
    try {
      const response = await GetCourseList(CourseData);
      setCourseList(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const NavigateCourseDeatils = (Course_id) => {
    setCourse_id(Course_id);
    navigate(`/CoursesDetails/${Course_id}`);
  };

  console.log("CourseList", CourseList);
  useEffect(() => {
    HandleCourseList();
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {
        <>
          <Header />
          <div className="banner-area bg-white py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-content">
                    <span
                      data-aos="fade-up"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                    >
                      FOR A BETTER FUTURE
                    </span>
                    <h1
                      data-aos="fade-down"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                    >
                      Take your team collaboration to the next level
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                    >
                      Book a session with freshgrad mentors across domain &amp;
                      work together to build your career!
                    </p>
                    <div
                      className="banner-form-area"
                      data-aos="fade-down"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                    ></div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="banner-img"
                    data-speed="0.05"
                    data-revert="true"
                  >
                    <img
                      src={`${IMG_BASE_URL}/trainer-banner.38e71f44682f22b5e9a6.png`}
                      data-aos="fade-up"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                      alt="Man"
                    />
                    <div
                      className="right-content shadow"
                      data-aos="fade-down"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                    >
                      <i className="flaticon-checked" />
                      <div className="content">
                        <h3>$4578521.95</h3>
                        <p>This month total earn</p>
                      </div>
                    </div>
                    <div
                      className="left-content shadow"
                      data-aos="fade-up"
                      data-aos-delay={900}
                      data-aos-duration={1000}
                      data-aos-once="true"
                    >
                      <div className="content">
                        <img   src={`${IMG_BASE_URL}/download (6).jpg`} alt="User" />
                        <h3>User experience class</h3>
                        <p>Today at 12.00 PM</p>
                      </div>
                    </div>
                    <div className="banner-img-shape">
                      <div
                        className="shape1"
                        data-aos="fade-up"
                        data-aos-delay={900}
                        data-aos-duration={1000}
                        data-aos-once="true"
                      >
                        <img src={CommonImageTransport.shape3} alt="Shape" />
                      </div>
                      <div
                        className="shape2"
                        data-aos="fade-down"
                        data-aos-delay={900}
                        data-aos-duration={1000}
                        data-aos-once="true"
                      >
                        <img src={CommonImageTransport.shape2} alt="Shape" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-shape">
              <div className="banner-shape1">
                <img src={CommonImageTransport.shape1} alt="Shape" />
              </div>
            </div>
          </div>
          <div className="courses-area py-5 bg-light">
            <div className="container">
              <ToastContainer style={{ marginTop: "100px" }} />
              <div className="section-title text-center mb-45">
                <h2>All Courses</h2>
                <p>We found 09 courses available for you</p>
              </div>
              <div className="row">
                {CourseList?.slice(0, 6)?.map((CourseListResult) => {
                  return (
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        className="col-lg-4 col-md-6"
                        onClick={() =>
                          NavigateCourseDeatils(CourseListResult?.id)
                        }
                      >
                        <div className="courses-item">
                          <img
                            src={CourseListResult?.course_img}
                            alt="Courses"
                          />

                          <div className="content">
                            <Link to="#" className="tag-btn">
                              {CourseListResult?.category}
                            </Link>
                            <div className="price-text">Free</div>
                            <h3>
                              {" "}
                              <Link to="#">{CourseListResult?.title}</Link>
                            </h3>
                            <ul className="course-list">
                              <li>
                                <i className="ri-vidicon-fill"></i>{" "}
                                {CourseListResult?.total_classes} Days
                              </li>
                            </ul>
                            <div className="bottom-content">
                              <Link to="#" className="user-area">
                                <img
                                  src={CourseListResult?.instructor_img}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                  }}
                                  alt="Instructors"
                                />
                                <h3>{CourseListResult?.instructor_name}</h3>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

                {/* <div  className="col-lg-12 col-md-12 text-center">
                    <div className="pagination-area">
                      <Link to="courses.html" className="prev page-numbers">
                        <i className="flaticon-left-arrow" />
                      </Link>
                      <span className="page-numbers current" aria-current="page">1</span>
                      <Link to="" className="page-numbers">2</Link>
                      <Link to="courses.html" className="page-numbers">3</Link>
                      <Link to="courses.html" className="next page-numbers">
                        <i className="flaticon-chevron" />
                      </Link>
                    </div>
                  </div> */}
              </div>
              {CourseList?.length > 6 && (
                <div class="col-lg-12 col-md-12 text-center">
                  <Link
                    to="/Course_List"
                    class="default-btn rounded"
                  >
                    View All
                  </Link>
                </div>
              )}
            </div>
          </div>

          <TopMentors />
          {/* <Subscribe /> */}
          <BackToTop />
          <Footer />
        </>
      }
    </div>
  );
};

export default TrainingPrograms;
