// import React, { useState, useEffect } from 'react';
// import { Student_Attempt_Update } from '../../../../api/Student';
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Student_Timer_Countdown = () => {
//   const [timeLeft, setTimeLeft] = useState(null);
//   console.log("timeLeft", timeLeft);

//   // <---------- User Authentication Token ------------>
//   const token = localStorage.getItem("User_Token");
//   const headers = {
//     "Authorization": `Bearer ${token}`
//   };

//   useEffect(() => {
//     const timerStartDate = localStorage.getItem('timer_start_date');
//     console.log("timerStartDate",timerStartDate)
//     const timerDate = localStorage.getItem('timer_date');

//     if (!timerStartDate || !timerDate) {
//       return; // ✅ Exit if data is missing
//     }

//     const startDate = new Date(timerStartDate);
//     // startDate.setHours(startDate.getHours() + 5); // ✅ Start 5 hours later when you want to show the timer on the EST 8:00 AM 
//     startDate.setHours(startDate.getHours()); // ✅ Start 5 hours later


//     const endDate = new Date(timerDate);
//     // endDate.setHours(endDate.getHours() + 5); // ✅ End 5 hours later
//     endDate.setHours(endDate.getHours()); // ✅ End 5 hours later
//     const currentDate = new Date();
//     if (currentDate >= startDate) {
//       startCountdown(endDate);
//     } else {
//       // Wait until the delayed start time
//       setTimeout(() => {
//         startCountdown(endDate);
//       }, startDate - currentDate);
//     }

//     return () => clearInterval(window.countdownInterval);
//   }, []); 

//   const startCountdown = (endDate) => {
//     if (window.countdownInterval) {
//       clearInterval(window.countdownInterval);
//     }

//     window.countdownInterval = setInterval(() => {
//       const now = new Date();
//       const newTimeLeft = endDate - now;

//       if (newTimeLeft <= 0) {
//         clearInterval(window.countdownInterval);
//         setTimeLeft(0);
//         Handle_Student_Attempt_Update();
//       } else {
//         setTimeLeft(newTimeLeft);
//       }
//     }, 1000);
//   };

//   const Handle_Student_Attempt_Update = async () => {
//     const course_id = localStorage.getItem("course_id");
//     const current_timer_id = localStorage.getItem("current_timer_id");
//     const User_id = localStorage.getItem("User_id");

//     const data = {
//       course_id,
//       class_id: current_timer_id,
//       student_id: User_id,
//     };

//     try {
//       const response = await Student_Attempt_Update(data, headers);
//       console.log("Update Successful:", response);

//       if (response?.data?.statusCode === "200") {
//         toast.error(response?.data?.msg);
//         localStorage.removeItem("timer_date");
//         localStorage.removeItem("timer_start_date");
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       } else if (response?.data?.statusCode === "400") {
//         alert(response?.data?.msg);
//       }
//     } catch (error) {
//       console.error("Update Failed:", error);
//     }
//   };

//   const formatTimeLeft = (milliseconds) => {
//     const totalSeconds = Math.floor(milliseconds / 1000);
//     const hours = Math.floor(totalSeconds / 3600);
//     const minutes = Math.floor((totalSeconds % 3600) / 60);
//     const seconds = totalSeconds % 60;

//     return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
//   };

//   return (
//     <div>
//       {timeLeft !== null && timeLeft > 0 && (
//         <h6 className="text-success">{formatTimeLeft(timeLeft)}</h6>
//       )}
//     </div>
//   );
// };

// export default Student_Timer_Countdown;


import React, { useState, useEffect } from 'react';
import { Student_Attempt_Update } from '../../../../api/Student';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Student_Timer_Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [formattedStartDate, setFormattedStartDate] = useState("");
  console.log("timeLeft", timeLeft);

  // <---------- User Authentication Token ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  };

  useEffect(() => {
    const timerStartDate = localStorage.getItem('timer_start_date');
    console.log("timerStartDate", timerStartDate)
    const timerDate = localStorage.getItem('timer_date');

    if (!timerStartDate || !timerDate) {
      return; // ✅ Exit if data is missing
    }

    const startDate = new Date(timerStartDate);
    // startDate.setHours(startDate.getHours() + 5); // ✅ Start 5 hours later when you want to show the timer on the EST 8:00 AM 
    startDate.setHours(startDate.getHours()); // ✅ Start 5 hours later
    const formattedUTCDateTime = formatUTCDateTime(startDate);

    setFormattedStartDate(formattedUTCDateTime);

    const endDate = new Date(timerDate);
    // endDate.setHours(endDate.getHours() + 5); // ✅ End 5 hours later
    endDate.setHours(endDate.getHours()); // ✅ End 5 hours later
    const currentDate = new Date();
    if (currentDate >= startDate) {
      startCountdown(endDate);
    } else {
      // Wait until the delayed start time
      setTimeout(() => {
        startCountdown(endDate);
      }, startDate - currentDate);
    }

    return () => clearInterval(window.countdownInterval);
  }, []);

  const startCountdown = (endDate) => {
    if (window.countdownInterval) {
      clearInterval(window.countdownInterval);
    }

    window.countdownInterval = setInterval(() => {
      const now = new Date();
      const newTimeLeft = endDate - now;

      if (newTimeLeft <= 0) {
        clearInterval(window.countdownInterval);
        setTimeLeft(0);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        Handle_Student_Attempt_Update();
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);
  };

  const Handle_Student_Attempt_Update = async () => {
    const course_id = localStorage.getItem("course_id");
    const current_timer_id = localStorage.getItem("current_timer_id");
    const User_id = localStorage.getItem("User_id");

    const data = {
      course_id,
      class_id: current_timer_id,
      student_id: User_id,
    };

    try {
      const response = await Student_Attempt_Update(data, headers);
      console.log("Update Successful:", response);

      if (response?.data?.statusCode == "200") {
        toast.error(response?.data?.msg);
        localStorage.removeItem("timer_date");
        localStorage.removeItem("timer_start_date");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (response?.data?.statusCode == "400") {
        // alert(response?.data?.msg);
      }
    } catch (error) {
      console.error("Update Failed:", error);
    }
  };

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // ✅ Function to format UTC Date & Time with AM/PM
  const formatUTCDateTime = (date) => {
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${month}-${day}-${year}, ${hours}:${minutes} ${ampm} (UTC)`;
  };

  return (
    <div>
      {/* Display formatted date with timezone */}
      <h6 className="text-primary">Class Opening Time: {formattedStartDate}</h6>

      {/* Display countdown timer */}
      {timeLeft !== null && timeLeft > 0 && (
        <h6 className="text-success">{formatTimeLeft(timeLeft)}</h6>
      )}
    </div>
  );
};

export default Student_Timer_Countdown;