import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/common/header/Header';
import CommonImageTransport from '../../components/common/images/CommonImages';
import { SendOtp, UserLogin } from '../../api/Auth';
import { CheckEmail } from '../../api/Auth';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';

const Signin = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);

    // <------- Set the Time Zone here ------------->
    const DateDataFirst = new Date();
    const timeZone = moment.tz.guess();
    const timeZoneAbbr = moment.tz(DateDataFirst, timeZone).format('z');
    console.log("timeZoneAbbr", timeZoneAbbr)

    // <------ get the Active Tabs from the Localstorage ------------->
    const GetActiveTabs = localStorage.getItem("ActiveTabs");

    const handleUsernameSubmit = async (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);
        try {
            const response = await CheckEmail({ email: enteredEmail })
            if (response.data.data.is_exists === true) {
                setShowPasswordField(true);
                setError('');

            } else {
                setShowPasswordField(false);
                setError('Wrong Email Id');

            }
        } catch (err) {
            setError(err.response?.data.message || err.message || 'An error occurred');
        }
    };

    // <-------- old login api for the send the otp and navigate the otp verify page here -------------->
    // const LoginApi = async (e) => {
    //     e.preventDefault()
    //     setIsLoading(true); 
    //     try {
    //         if (!email || !password) {
    //             setError('Please enter email and password.');
    //             setIsLoading(false); 
    //             return;
    //         }
    //         const data = {
    //             type: "login",
    //             email: email,
    //             password: password,
    //             time_zone: "EST",
    //             // time_zone: timeZoneAbbr,
    //         };
    //         const response = await SendOtp(data);
    //         console.log("response", response?.data?.msg);
    //         if (response.data.statusCode === "200") {
    //             toast.success("Otp sent to your email, please check");
    //             setEmail('');
    //             setPassword('');
    //             localStorage.setItem("user_Email", response.data.data.email);
    //             localStorage.setItem("user_Login_Data", JSON.stringify(data));
    //             localStorage.setItem("user_Login_Time_Zone", JSON.stringify(data?.time_zone));
    //             if (rememberMe) {
    //                 localStorage.setItem('rememberedUser', JSON.stringify({ email, password }));
    //             } else {
    //                 localStorage.removeItem('rememberedUser');
    //             }
    //             setTimeout(() => {
    //                 navigate("/Otp");
    //                 setIsLoading(false); 
    //             }, 2000);
    //         } else {
    //             if (response?.data?.statusCode == "400") {
    //                 setIsLoading(false); 

    //                 setTimeout(()=>{
    //                     toast.error(response?.data?.msg);
    //                 },1000)
    //                 setTimeout(()=>{
    //                     window.location.reload(); 
    //                 },4000)
    //             } else {
    //                 toast.error("Error: " + response?.data?.msg);
    //                 setIsLoading(false); 
    //             }
    //         }
    //     } catch (error) {
    //         console.log("Error:", error);
    //         setIsLoading(false); 
    //     }
    // };


    const LoginApi = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            if (!email || !password) {
                setError('Please enter email and password.');
                setIsLoading(false);
                return;
            }
            const data = {
                type: "login",
                email: email,
                password: password,
                time_zone: "EST",
                // time_zone: timeZoneAbbr,
            };
            // const response = await OtpVerify(SendOtpVerifyData)
            // console.log("OtpVerify_OtpVerify", response)
            // if (response.data.data.isOtpMatch == true) {
            const Login_response = await UserLogin(data);
            console.log("Login_response", Login_response)
            if (Login_response?.data?.data?.user?.role_id === 2) {
                const User_Token = Login_response?.data?.token;
                const User_id = Login_response?.data?.data?.user?.id;
                const role_id = Login_response?.data?.data?.user?.role_id;
                // localStorage.setItem("Is_LoggedIn", true);
                localStorage.setItem("User_Token", User_Token);
                localStorage.setItem("User_id", User_id);
                localStorage.setItem("user_Email", Login_response?.data?.data?.user?.email);
                // localStorage.setItem("role_id", role_id);
                // localStorage.removeItem("user_Email");
                // localStorage.removeItem("user_Login_Data");
                // toast.success("Otp verified Successful & student login Successful !!!")
                localStorage.removeItem("ActiveTabs")
                setTimeout(() => {
                    navigate("/Student_step");
                    setIsLoading(false)
                })

            }

            if (Login_response?.data?.data?.user?.role_id === 4) {
                const User_Token = Login_response?.data?.token;
                const User_id = Login_response?.data?.data?.user?.id;
                const role_id = Login_response?.data?.data?.user?.role_id;
                localStorage.setItem("Is_LoggedIn", true);
                localStorage.setItem("User_Token", User_Token);
                localStorage.setItem("User_id", User_id);
                localStorage.setItem("role_id", role_id);
                // localStorage.removeItem("user_Email");
                // localStorage.removeItem("user_Login_Data");
                // toast.success("Otp verified Successful & Recruiter login Successful !!!")
                localStorage.removeItem("ActiveTabs")
                setTimeout(() => {
                    navigate("/recruiter_pages/RecruiterStep");
                    setIsLoading(false)
                })

            }

            if (Login_response?.data?.data?.user?.role_id === 3) {
                const User_Token = Login_response.data.token;
                const User_id = Login_response?.data?.data?.user?.id;
                const role_id = Login_response?.data?.data?.user?.role_id;
                localStorage.setItem("Is_LoggedIn", true);
                localStorage.setItem("User_Token", User_Token);
                localStorage.setItem("User_id", User_id);
                localStorage.setItem("role_id", role_id);
                // localStorage.removeItem("user_Email");
                // localStorage.removeItem("user_Login_Data");
                setIsLoading(false)
                // toast.success("Otp verified Successful & mentor login Successful !!!")
                localStorage.removeItem("ActiveTabs")
                setTimeout(() => {
                    navigate("/mentor_pages/Mentor-Step");
                    setIsLoading(false)
                })
            }
            if (Login_response?.data?.data?.user?.role_id === 6) {
                const User_Token = Login_response.data.token;
                const User_id = Login_response?.data?.data?.user?.id;
                const role_id = Login_response?.data?.data?.user?.role_id;
                // localStorage.setItem("Is_LoggedIn", true);
                localStorage.setItem("team_token", User_Token);
                localStorage.setItem("team_id", User_id);
                localStorage.setItem("role_id", role_id);
                localStorage.setItem("team_role", Login_response?.data?.data?.user?.member_role)
                // localStorage.removeItem("user_Email");
                // localStorage.removeItem("Team_Login_Data");
                setIsLoading(false)
                // toast.success("Otp verified Successful & team member login Successful !!!")
                localStorage.removeItem("ActiveTabs")
                setTimeout(() => {
                    navigate("/team_member_feedback");
                    setIsLoading(false)
                })
            }
            if (Login_response?.data?.data?.user?.role_id === 5) {
                const User_Token = Login_response.data.token;
                const User_id = Login_response?.data?.data?.user?.id;
                const role_id = Login_response?.data?.data?.user?.role_id;
                // localStorage.setItem("Is_LoggedIn", true);
                localStorage.setItem("team_token", User_Token);
                localStorage.setItem("team_id", User_id);
                localStorage.setItem("role_id", role_id);
                localStorage.setItem("team_role", Login_response?.data?.data?.user?.member_role)
                // localStorage.removeItem("user_Email");
                // localStorage.removeItem("Team_Login_Data");
                setIsLoading(false)
                // toast.success("Otp verified Successful & team member login Successful !!!")
                localStorage.removeItem("ActiveTabs")
                setTimeout(() => {
                    navigate("/team_member_feedback");
                    setIsLoading(false)
                })
            }
            if (Login_response?.response?.data?.statusCode == "400") {
                alert(Login_response?.response?.data?.msg)
                navigate("/Signin")
                setIsLoading(false)

            }

            if (Login_response?.data?.statusCode == "400") {
                setIsLoading(false)
                setTimeout(() => {
                    alert("Sorry User deatils don't meet the our requirements")
                    // toast.error("Sorry User deatils don't meet the our requirements")
                }, 2000)
            }

            if (Login_response?.data?.statusCode == "400") {
                setIsLoading(false);

                setTimeout(() => {
                    toast.error(Login_response?.data?.msg);
                }, 1000)
                setTimeout(() => {
                    window.location.reload();
                }, 4000)
            } else {
                // toast.error("Error: " + Login_response?.data?.msg);
                setIsLoading(false);
            }
            console.log("Login_response", Login_response)

            // }
            // if (response.data.statusCode == "400") {
            //     setIsLoading(false)
            //     setTimeout(() => {
            //         toast.error(response.data.msg)
            //     }, 1000)
            // }
        } catch (error) {
            console.log("Error:", error);
            setIsLoading(true)
        }
    };


    // Function to handle checkbox change
    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
    };

    // Effect to load remembered user if present
    useEffect(() => {
        const rememberedUser = localStorage.getItem('rememberedUser');
        if (rememberedUser) {
            const { username } = JSON.parse(rememberedUser);
            setEmail(username);
            setRememberMe(false);
        }
    }, []);
    useEffect(() => {
        document.title = '::Signin::';
    }, []);

    return (
        <div className="login-signup-bg">
            <Header />
            {
                isLoading ? (
                    <div className="chat-window text-center">
                        <div style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                        }} className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                ) : (
                    <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                        <div className="container">
                            <div className="row">
                                <ToastContainer style={{ marginTop: "120px" }} />
                                <div className="col-lg-6 d-none d-md-none d-lg-block p-0">
                                    <div className="user_login">
                                        <img src={CommonImageTransport.loginbg} className="img-fluid w-100" alt="login-banner" />
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex p-0">
                                    <div className="user-all-form userlogin position-relative">
                                        <div className="login_bottom">
                                            <img src={CommonImageTransport.loginBottom} alt="" />
                                        </div>
                                        <div className="contact-form">

                                            <h3 className="user-title"> Log In to your Account</h3>
                                            <p>Welcome back! Select method to log in:</p>
                                            <form id="contactForms" className="mt-5" onSubmit={LoginApi}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-floating form-group">
                                                            <input type="email" required className="form-control" id="floatingInput"
                                                                placeholder=""
                                                                onChange={handleUsernameSubmit} />
                                                            <label htmlFor="floatingInput">Email ID</label>
                                                        </div>
                                                    </div>
                                                    {showPasswordField && (
                                                        <div className="col-12">
                                                            {/* <div className="form-floating form-group">
                                                            <input type="password" required className="form-control" id="floatingPassword"
                                                                placeholder="xxxxxxxx" 
                                                                onChange={(e) => setPassword(e.target.value)} />
                                                            <label htmlFor="floatingPassword">Password</label>
                                                        </div> */}
                                                            <div className="form-floating form-group">
                                                                <input
                                                                    type={showPassword ? "text" : "password"}
                                                                    className="form-control"
                                                                    id="floatingPassword"
                                                                    placeholder="xxxxxxxx"
                                                                    value={password}
                                                                    required
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                />
                                                                <label htmlFor="floatingPassword">Password</label>
                                                                <span onClick={() => setShowPassword(!showPassword)}
                                                                    className="password-toggle eye">
                                                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    )}
                                                    <div className="col-lg-12 form-condition">
                                                        <div className="agree-label">
                                                            <input type="checkbox" id="chb1" checked={rememberMe}
                                                                onChange={handleCheckboxChange} />
                                                            <label htmlFor="chb1">
                                                                Remember Me <Link className="forget"
                                                                    to="/SendOtpForgotPass">Forgot
                                                                    Password?</Link>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <button type="submit"
                                                            className={`default-btn w-100 rounded ${showPasswordField ? '' : 'disabled'}`}
                                                        >
                                                            Login
                                                        </button>
                                                        {error && <p className="text-danger mt-2">{error}</p>}
                                                        <h6 className="mt-4 text-center fw-normal text-muted">
                                                            Don’t have an account?
                                                            <Link className="fw-bold"
                                                                // to={
                                                                //     GetActiveTabs === "student"
                                                                //         ? "/Student_SignUp"
                                                                //         : GetActiveTabs === "Recruiter"
                                                                //             ? "/Recruiter_SignUp"
                                                                //             : GetActiveTabs === "Mentor"
                                                                //                 ? "/Mentor_SignUp"
                                                                //                 : "/Student_SignUp" 
                                                                // }
                                                                to="/Student_SignUp"
                                                            >
                                                                Create an account
                                                            </Link>
                                                        </h6>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Signin